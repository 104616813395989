import React, { useEffect, useState, ReactNode } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
  Button,
  Grid,
  Typography,
  Select,
  MenuItem,
  SelectChangeEvent,
  Box,
} from '@mui/material';

import { ComisionInterface, UserAttributes } from '../../../../interfaces/models';

import './Venta.css';
//TODO: agregar confirmacion visual del ticket creado realiaza exitosamente


interface Ticket {
  user_id_sale_made: number;
  id_service_product: number;
  oper: string;
  descripcion: string;
  cantidad: number;
  precioUnitario: number;
  tipoComision: number;
  comisionActiva: boolean;
  costoComisionAPagar: number;
  cantidadEnvase: string;
}

const generarCodigoUsuarioTexto = (firstNameUser:string, lastNameUser:string) : string =>{
  const nombresDivididos = firstNameUser.trim().split(/\s+/);
  const apellidosDivididos = lastNameUser.trim().split(/\s+/);

  // Extrae las letras iniciales según las condiciones
  const letrasNombre = nombresDivididos.length === 1
    ? nombresDivididos[0].substring(0, 2) // Si solo hay un nombre, toma las primeras dos letras
    : nombresDivididos.map(nombre => nombre[0]).join(''); // Si hay más de un nombre, toma la primera letra de cada uno

  const letrasApellido = apellidosDivididos.map(apellido => apellido[0]).join(''); // Toma la primera letra de cada palabra del apellido

  // Combina y convierte a mayúsculas
  const codigoUsuario = (letrasNombre + letrasApellido).toUpperCase();
  //setUserLogin(codigoUsuario);
  return codigoUsuario
}
interface VentaIzquierdoProps {
  tickets: Ticket[];
  updateTicketOper: (index: number, usuarioId: number) => void;
  usuarios: UserAttributes[];
  handleTipoComisionChange: (event: SelectChangeEvent<string>, index: number) => void;
  comisionActual: ComisionInterface[];
  handleCantidadChange: (event: React.ChangeEvent<HTMLInputElement>, index: number) => void;
  handleCantidadBlur: (event: React.FocusEvent<HTMLInputElement>, index: number) => void;
  calculateTotal: (cantidad: number, precioUnitario: number) => number;
  removeTicket: (indexToRemove: number) => void;
  calculateGrandTotal: (data: Ticket[]) => number;
  observaciones: string;
  editingValues: { [key: number]: string };
  setEditingValues: React.Dispatch<React.SetStateAction<{ [key: number]: string }>>;
}



export const VentaIzquierdo: React.FC<VentaIzquierdoProps> = ({
    tickets,
    updateTicketOper,
    usuarios,
    handleTipoComisionChange,
    comisionActual,
    handleCantidadChange,
    handleCantidadBlur,
    calculateTotal,
    removeTicket,     
    calculateGrandTotal,
    observaciones,
    editingValues,
    setEditingValues
}) => {

            {/* Lado Izquierdo: Sección de Ticket */}
            return (
              <Grid item xs={12} md={12}>
                <Box display="flex" justifyContent="center">
                  <Typography
                    variant="h6"
                    gutterBottom
                    component="div"
                    style={{
                      color: `rgb(0, 0, 255)`,
                      fontWeight: 'bold', // Aplica negrilla al texto
                    }}
                  >
                    DESCRIPCION DE TICKET
                  </Typography>
                </Box>
            
                <Box display="flex" justifyContent="center">
                  <Typography variant="body2">{new Date().toLocaleString()}</Typography>
                </Box>
            
                {/* Ajuste en el contenedor de la tabla para que ocupe el 100% del ancho */}
                <TableContainer component={Paper} sx={{ width: '100%', marginTop: 2 }}>
                  <Table aria-label="ticket table">
                    <TableHead>
                      <TableRow>
                        {['OPER', 'COMISION', 'DESCRIPCION', 'CANT.', 'PRE. U.', 'PRE. T.'].map((header, index) => (
                          <TableCell key={index}>
                            <Typography variant="subtitle1" component="div" style={{ fontWeight: 'bold', fontSize: 'larger' }}>
                              {header}
                            </Typography>
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
            
                    <TableBody>
                      {tickets.map((ticket, index) => (
                        <TableRow key={index}>
                          <TableCell>
                            <Select
                              value={ticket.oper}
                              onChange={(e) => {
                                const usuarioId = parseInt(e.target.value, 10);
                                if (!isNaN(usuarioId)) {
                                  updateTicketOper(index, usuarioId);
                                }
                              }}
                              displayEmpty
                              sx={{ width: '100%' }} // Se asegura que el select ocupe todo el espacio
                            >
                              {usuarios.map((usuario) => (
                                <MenuItem key={usuario.idUser} value={usuario.idUser}>
                                  {usuario.firstName} {usuario.lastName}
                                </MenuItem>
                              ))}
                            </Select>
                          </TableCell>
            
                          <TableCell>
                            {ticket.comisionActiva && (
                              <Select
                                value={ticket.tipoComision.toString()}
                                onChange={(e) => handleTipoComisionChange(e, index)}
                                displayEmpty
                                sx={{ width: '100%' }} // Se asegura que el select ocupe todo el espacio
                              >
                                {comisionActual.map((comision, comisionIndex) => (
                                  <MenuItem key={comisionIndex} value={comisionIndex.toString()}>
                                    {comision.comision} {comision.tipo}
                                  </MenuItem>
                                ))}
                                <MenuItem value="NA">NA</MenuItem>
                              </Select>
                            )}
                          </TableCell>
            
                          <TableCell>{ticket.descripcion}</TableCell>
            
                          <TableCell>
                            <TextField
                              type="number"
                              value={editingValues[index] !== undefined ? editingValues[index] : ticket.cantidad.toString()}
                              onChange={(e) => handleCantidadChange(e as React.ChangeEvent<HTMLInputElement>, index)}
                              onBlur={(e) => handleCantidadBlur(e as React.FocusEvent<HTMLInputElement>, index)}
                              InputProps={{
                                inputProps: { min: 1 },
                              }}
                              variant="outlined"
                              size="small"
                              sx={{ width: '100%' }} // Asegura que el campo de texto ocupe todo el espacio disponible
                            />
                          </TableCell>
            
                          <TableCell>{ticket.precioUnitario.toFixed(2)}</TableCell>
                          <TableCell>{calculateTotal(ticket.cantidad, ticket.precioUnitario).toFixed(2)}</TableCell>
            
                          <TableCell sx={{ padding: 0, minWidth: '50px' }}>
                            <Button onClick={() => removeTicket(index)} sx={{ minWidth: '10px', padding: 0 }}>
                              X
                            </Button>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
            
                <Typography variant="h6" sx={{ marginTop: 2 }}>
                  Total Bs. {calculateGrandTotal(tickets).toFixed(2)}
                </Typography>
                
                <Typography variant="body1" sx={{ marginTop: 2 }}>
                  Observaciones: {observaciones}
                </Typography>
              </Grid>
            );
            
};
